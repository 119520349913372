
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F3DFE3;
}
.header {
  background: #F0BEC8;
  position: sticky;
  top: 0;
  padding: 5px;
  padding-top: 1px;
  padding-left: 10px;
  z-index: 8;
  font-family: 'Cormorant SC', serif;
}
.blurb {
  font-family: 'Cormorant SC', serif;
  font-size: 2em;
  max-width: 800px;
  padding-left: 30px;
  color:#DD798C;
  font-weight: bolder;
}
#welcome {
  font-family: 'Alex Brush', cursive;
  color:#1D6947;
  font-weight: bold;
  font-size: 25px;
  position: absolute;
  right: 165px;
  top: 38px;
}
#title {
  font-size: 3em;
  color: #1D6947;
  margin-left: 40px;
  margin-bottom: 0px;
  font-family: 'Cormorant SC', serif;
}
#blog-title {
  color: #1D6947;
  margin-left: 40px;
  font-family: 'Cormorant SC', serif;
  font-size: 2em;
  margin-top: 40px;
  margin-bottom: 10px;
}
#food-title {
  color: #1D6947;
  margin-left: 40px;
  font-family: 'Cormorant SC', serif;
  font-size: 2em;
  margin-top: 40px;
  margin-bottom: 10px;
}
#phrase {
  font-family: 'Alex Brush', cursive;
  margin-left: 180px;
  font-size: 2em;
  color: #1D6947;
  margin-bottom: 20px;
  margin-top: 0px;
  margin-left: 180px;
}

#nav-links-button {
  font-family: 'Cormorant SC', serif;
  background-color: #DD798C;
  color: #1D6947;
  padding:  5px;
  text-decoration: none;
  position: absolute;
  top: 25px;
  right: 25px;
}

.NavBarItems {
  background: #F0BEC8;
  margin-bottom: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 25px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: left;
  margin: 0;
  padding: 0;
}

.nav-links {
  color: #1D6947;
  text-decoration: none;
  padding: 10px;
  background-color: #DD798C;
}
.nav-links:hover {
  background-color: #F3DFE3;
}

 /* Carousel CSS */
 .carousel-container {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 10px;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

.left-arrow, .right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}

.carousel-content.show-2 > * {
  width: 50%;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.carousel-content.show-4 > * {
  width: calc(100% / 4);
}
/* About Page */

.rachel img { 
  position: relative;
  border-radius: 50%;
  margin: 40px;
  width: auto; 
  max-height: 800px;

}
#contact {
  font-family: 'Cormorant SC', serif;
  color: #1D6947;
  font-size: 2em;
  padding: 60px;
  padding-left: 60px;
  margin-left: 20px;
  margin-top: 40px;
  padding-bottom: 0px;
}
.about {
  display: flex;
}
.socialMediaLinks {
  padding-left: 85px;
  display: flex;
}
.ig {
  font-family: 'Cormorant SC', serif;
  font-size: 30px;
  margin-left: 5px;
  margin-top: 10px;
  color: #1D6947;
}
.email {
  font-family: 'Cormorant SC', serif;
  color: #1D6947;
  font-size: 30px;
  margin-left: 85px;
}
.bio {
  color: #1D6947;
  font-size: 30px;
  font-family: 'Cormorant SC', serif;
  font-weight: bold;
  width: 700px;
  padding-left: 150px;
  padding-top: 80px;
}
.lol{
  color: #DD798C;
  font-size: 2em;
  font-family: 'Alex Brush', cursive;
  position: absolute;
  right: 30px; 
  width: 300px; 
}
/* Rachel Made It Page */
.bakedgoods-list, .blogs-list {
  list-style-type: none;
  display: inline-block;
}
.foodImage, .blogImage {
  width: 400px;
  height: auto;
  z-index: 7;
  float: left;
}

.foodName, .blogName {
  color:#1D6947;
  font-family: 'Cormorant SC', serif;
  font-size: 30px;
  font-weight: bold;
  width: auto;
  padding: 10px;
  height: 35px;
  margin-left: 25px;
}
.foodDescription, .blogDescription, .blogRestaurant, .foodPrice{
  font-family: 'Cormorant SC', serif;
  font-size: 20px;
  max-width: 600px;
  color:#1D6947;
}
.reviews {
  font-family: 'Cormorant SC', serif;
  font-size: 30px;
  max-width: 600px;
  color:#1D6947;
}
#hr {
  background-color: #DD798C;
  height: 1px; 
  border: 0;
}
.review-link, .comment-link{
  color: #1D6947;
  text-decoration: none;
  padding: 10px;
  background-color: #F0BEC8;
  font-family: 'Cormorant SC', serif;
  font-size: 25px;
}

/* Baked Good & Blog Page */
#bg-title, #bp-title {
  font-size: 3em;
  color: #1D6947;
  margin-left: 40px;
  margin-bottom: 0px;
  font-family: 'Cormorant SC', serif;
}
#bg-image, #bp-image {
  max-height: 600px;
  width: auto;
  padding: 40px;
  display: inline-block;
}
.bg-page, .bp-page {
  display: flex;
}
#bg-price, #bp-restaurant {
  font-family: 'Cormorant SC', serif;
  color: #1D6947;
  font-size: 30px;
  padding-top: 10px;
  font-weight: bold;
}
#bg-description, #bp-description {
  font-family: 'Cormorant SC', serif;
  color: #1D6947;
  font-size: 20px;
  padding: 0;
  margin: 0;
  max-width: 600px;
}
#bp-post {
  font-family: 'Cormorant SC', serif;
  color: #1D6947;
  font-size: 27px;
  padding: 0;
  margin: 0;
  max-width: 600px;
}
#bg-reviews, #bp-comments {
  font-family: 'Alex Brush', cursive;
  color: #1D6947;
  font-size: 30px;
  max-width: 600px;
}
.review-form {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #1D6947;
  
}
.form-input{
  display: flex;
  vertical-align: middle;
  padding-bottom: 20px;
  
}
.form-buttons{
  display: flex;
  position: absolute;
  left: 38%;
}
.update-buttons{
  display: flex;
  position: absolute;
  left: 27%;
}
.comment-buttons{
  display: flex;
  position: absolute;
  left: 32%;
}

/* Account Page */
.user-name{
  color: #1D6947;
  font-family: 'Cormorant SC', serif;
  padding-left: 20px;
  font-size: 30px;
}
.account-reviews, .account-comments{
  color: #1D6947;
  font-family: 'Cormorant SC', serif;
  padding-left: 20px;
  
}

